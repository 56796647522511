import React from 'react'
import blogClasses from '../../PMPblog.module.css'
import Container from '../../../../UI/Container/Container'
import Heading from '../../../../UI/Heading/Heading'
import posts from '../../../../../../data/blog/posts'
import SEO from '../../../../seo'
import Signature from '../Signature/Signature'

const post = posts.filter(post => post.link === 'scrum-of-scrums-sos')[0]

export default () => (
  <Container width={1060}>
    <SEO
      title={`PMP Blog | ${post.title}`}
      keywords={[
        `Scrum of Scrums`,
        `SoS`,
        `Project Management Framework`,
        `PMP Exam`,
        `Project Management`
      ]}
      description="Scrum teams in large organizations. 4 characteristics of Scrum of Scrums. Tips on how to scale your Scrum Team."
    ></SEO>
    <Heading text={post.title} />
    <div className={blogClasses.Image}>{post.images[0]}</div>
    <p />
    <p className={blogClasses.NoBottomMargin}>
      Most of us are familiar with the basic principle of Scrum: according to
      the so-called 7 +/- 2 rule, Scrum teams should be 5 to 9 members. At the
      same time, Scrum guide says Scrum teams should not be less than 3 or more
      than 9. There is a minimum of 3 members in a Scrum team due to the fact
      that there are 3 major roles that are essential:
    </p>
    <div className={blogClasses.List}>
      <ul className={blogClasses.NoBottomMargin}>
        <li>
          <span>Product Owner</span>
        </li>
        <li>
          <span>Development Team</span>
        </li>
        <li>
          <span>Scrum Master</span>
        </li>
      </ul>
    </div>
    <p>
      And in a perfect scenario, there are no more than 9 members in a Scrum
      team to ensure that management and communication are kept as simple and
      effective as possible. Larger teams have more communication channels
      between team members, making it harder to create trust, respect and a
      sense of common purpose. Teams that are too small or large might struggle
      with the delivery of complex products.
    </p>
    <p>
      How do organizations deal with projects that have a lot of people working
      on it? Let's say there are 500 people on the same project and you want to
      use Scrum. Is it not applicable? Yes, it is applicable. Scrum can be used
      on large projects regardless of the number of its members. But, rather
      than scaling by having a large team, Scrum projects scale through having
      teams of teams. This technique, when two or more Scrum teams have to
      coordinate their work instead of one large Scrum team is called{' '}
      <b>Scrum of Scrums (SoS)</b>. It proved to be a great technique to connect
      multiple teams who need to work together to deliver complex solutions.
    </p>
    <p>
      The Scrum of Scrums methodology, also known as 'Meta Scrum', was first
      implemented in 1996 by Jeff Sutherland and Ken Schwaber. With this
      technique, Scrum teams operate as usual, but each of these teams chooses a
      representative who attends the Scrum of Scrums meetings in order to
      coordinate the work of multiple Scrum teams. Similarly, extremely large
      projects with many teams may result in conducting a Scrum of Scrum of
      Scrums, when a representative from each Scrum of Scrums is reporting to a
      group of other representatives. This structure is represented in the
      following scheme:
    </p>
    <div className={blogClasses.Image}>{post.images[1]}</div>
    <p />
    <p>
      Scrum of Scrums teams apply almost the same practices and have very
      similar roles as Scrum teams only with minor differences. Here are the
      main characteristics of a SoS:
    </p>
    <div className={blogClasses.List}>
      <ol>
        <li>
          <p>
            Even though SoS meetings are similar to the daily Scrum meetings,
            they <b>do not necessarily happen every day</b>. Typically, SoS
            meetings take place two to three times a week, which is sufficient
            for most organizations. Anyway, the frequency of SoS meetings must
            be determined by the team.
          </p>
        </li>
        <li>
          <p>
            There is one additional question to be discussed during SoS meetings
            in comparison to a regular Scrum meeting.
          </p>
          <p className={blogClasses.NoBottomMargin}>
            <b>The agenda for a Scrum meeting is:</b>
          </p>
          <ul className={blogClasses.NoTopPadding}>
            <li>
              <span>Completed work (what was done yesterday?)</span>
            </li>
            <li>
              <span>Next set of work (what will be done today?)</span>
            </li>
            <li>
              <span>
                Any current impediments (are there obstacles that slow the work
                down?)
              </span>
            </li>
          </ul>
          <p className={blogClasses.NoBottomMargin}>
            <b>The agenda for a SoS meeting:</b>
          </p>
          <ul className={blogClasses.NoTopPadding}>
            <li>
              <span>Completed work (what was done yesterday?)</span>
            </li>
            <li>
              <span>Next set of work (what will be done today?)</span>
            </li>
            <li>
              <span>
                Any current impediments (are there obstacles that slow the work
                down?)
              </span>
            </li>
            <li>
              <span>
                <b>Upcoming impediments that may block the other teams</b> (will
                you put something in another team's way?)
              </span>
            </li>
          </ul>
        </li>
        <li>
          <p>
            <b>
              The representatives of SoS meetings may change over the course of
              a typical project.
            </b>{' '}
            Who is going to attend a SoS meeting depends greatly on the
            questions most likely to be discussed at that time during the
            project. As a web developer, I personally attended SoS meetings a
            few times and according to my experience, my participation was
            required typically early in the project, when technical issues were
            most likely to arise.
          </p>
          <p>
            Similarly, if the work of the team is focused on design at the
            moment, a designer will most likely represent his/her Scrum team at
            the SoS meeting.
          </p>
        </li>
        <li>
          <p>
            The <b>SoS team size</b> depends on the number of teams on the
            project. Let's say there are 3 or 4 teams on the project. Is it
            possible to send two representatives from each Scrum team? Yes,
            actually it is. If each team sends two members to a SoS meeting, the
            SoS team will be 6-8 members accordingly, which is totally fine.
          </p>
        </li>
      </ol>
    </div>
    <p>
      To conclude it all, Scrum of Scrums facilitates cross-team coordination
      and ensures that the organization and individual Scrum teams are on the
      same page. Keep that technique in mind as it can be very effective in
      larger organizations with multiple teams.
    </p>
    <Signature date={post.date} author={post.author} />
  </Container>
)
