import React from 'react'
import Layout from '../../hoc/Layout/Layout'
import SoS from '../../components/Pages/PMPblog/articles/SoS/SoS'

export default () => {
  return (
    <Layout>
      <SoS />
    </Layout>
  )
}
